import React from 'react';
import {useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { QUERY_DATES } from '../utils/queries';
import {useNavigate } from 'react-router-dom';
import Canonical from '../components/Canonical'
import {QUERY_TIME_NOW} from '../utils/queries'
import { GET_PAYPAL_CCDI } from '../utils/queries';
import PickupsOnly from "./PickupsOnly"
import DeliveryOnly from "./DeliveryOnly"


const Order = ({
  cakeson,
tamalon,
order,
dlvdates,
pcpdates,
setObjDate,
howdelivery,
setHowDelivery,
TotalOrder,
selectedDate,
selectedTime,
myname,
deliveryInstructions,
myemail, 
myphone,
myaddress,
setSelectedDate,
setDeliveryInstructions, 
setSelectedTime,
setmyemail,
setmyname,
setmyphone,
setmyaddress,
mypostalcode,
setpostalcode,
isFilled,
setIsFilled,
selectedCity,
setSelectedCity,
deliveryFee,
setDeliveryFee,
setTotalOrder,
totalBeforeTaxes,
setTotalBeforeTaxes,
// taxes,
// setTaxes,
subTotal,
setKey,
keyppl
}) => {


const { data: data7, refetch: refetch7, loading:loading7, error: error7} = useQuery(QUERY_TIME_NOW);

const { data:data1, loading: loading1, refetch:refetch3} = useQuery(QUERY_DATES);
const alldates= data1?.Dates || [];

const { data, loading:paypalloading } = useQuery(GET_PAYPAL_CCDI);
const CCD = data?.getPaypal || [];
const keyPal = CCD.paypalCCID;

const navigate = useNavigate();

useEffect(() => {
  let fee = 0;
  switch (selectedCity) {
  case "Mississauga":
  case "Burlington":
  fee = 6.0*1;
  break;
  case "Oakville":
  fee = 4.0*1;
  break;
  case "Milton":
  fee = 8.0*1;
  break;
  default:
  fee = 0;
  }
  setDeliveryFee(fee);
}, [selectedCity, setDeliveryFee]);

// useEffect(() => {
//   const total = (deliveryFee*1) + (subTotal*1);
//   setTotalBeforeTaxes(total);
//   setTaxes((total * 0.13).toFixed(2));
// }, [subTotal, deliveryFee, setTotalBeforeTaxes, setTaxes]);
useEffect(() => {
  const total = (deliveryFee*1) + (subTotal*1);
  setTotalBeforeTaxes(total);
  
}, [subTotal, deliveryFee, setTotalBeforeTaxes]);

useEffect(() => {
  setKey(keyPal);
}, [keyPal, setKey]);

useEffect(() => {

  if(dlvdates==='0'){
  setHowDelivery('Pick up')
  }else if(pcpdates==='0'){
  setHowDelivery('Delivery')
  }else{
  return
  }

}, [setHowDelivery, dlvdates, pcpdates]);

useEffect(() => {

  if(howdelivery==='Pick up'){
  setpostalcode("None")
  setmyaddress("None")
  setSelectedCity("None")
  setDeliveryInstructions("Pick up location: 1130 Queens Ave, Oakville, L6H2B6. Please send us a text or watsApp message providing your invoice# once you arrive to the premises. Thank you")
  }else if(howdelivery===''){
  setSelectedTime('')
  setSelectedDate('')
  setpostalcode('')
  setmyaddress('')
  setSelectedCity('')
  setDeliveryInstructions('')
  }else{
  return
  }

}, [setSelectedCity, setSelectedTime, setSelectedDate, setpostalcode, howdelivery, setmyaddress, setDeliveryInstructions]);

// useEffect(() => {
//   const total = ((totalBeforeTaxes*1) + (taxes*1)).toFixed(2);
//   setTotalOrder(total);
// }, [totalBeforeTaxes, taxes, setTotalOrder]);
useEffect(() => {
  const total = (totalBeforeTaxes*1).toFixed(2);
  setTotalOrder(total);
}, [totalBeforeTaxes, setTotalOrder]);

const CancelOrder = () =>{
window.location.replace("/cakes_desserts_main")
}

const save = () =>{

if(myaddress && keyppl && myname && selectedDate && selectedTime && mypostalcode && selectedCity){
refetch3()
refetch7()

if(howdelivery==='Delivery'){
const hourtofind=   alldates
.find((date) => date.date === selectedDate)
.timeList.find((time) => time.time === selectedTime)

if(!hourtofind){
window.alert('time has been picked by another customer. Please try again')
return navigate('/Order')
}

setObjDate(hourtofind.timeInt)

if(hourtofind.isPicked===true){
const objTime = data7.getCurrentTimeMill
const currentTime = objTime.currentTimeMill
const timtosubstract = hourtofind.timePickedMill
if(((currentTime*1)-(timtosubstract*1))<12*60*1000){
window.alert('time has been picked by another customer. Please try again')
return navigate('/Order')
}else{
setIsFilled(true)
return navigate('/ProcessPayment')
}

}else{
setIsFilled(true)
return navigate('/ProcessPayment')
}
}else{
setIsFilled(true)
return navigate('/ProcessPayment')
}   

}else{
window.alert("you must fill all required fields")
}
}

const handleRedirectPayment = () =>{

navigate('/ProcessPayment')

}


const handleDeliveryInstructionsChange = (event) => {
setDeliveryInstructions(event.target.value);
};
const handleEmail= (event) => {
setmyemail(event.target.value);
};
const handlePhone= (event) => {
setmyphone(event.target.value);
};
const handleName= (event) => {
setmyname(event.target.value);
};
const handleAddress= (event) => {
setmyaddress(event.target.value);
};
const handlePostalCode= (event) => {
setpostalcode(event.target.value);
};

const handleSetDelivery= (event) => {
const selectedDelivery = event.target.value
if(howdelivery===selectedDelivery){

setHowDelivery('')
}else{
setHowDelivery(selectedDelivery)
}  
}

const handleCancel= async () => {
return window.location.replace('/cakes_desserts_main');
}


if(paypalloading===true){
  return(<div className='homemin'>loading...</div>)
}
if(loading7===true){
  return(<div className='homemin'>loading...</div>)
}
if(loading1===true){
  return(<div className='homemin'>loading...</div>)
}


if(isFilled===true){
return(
<div className='grid-container'>
<Canonical url="https://www.bitelandia.com/Order" />
<button className="proceedBtn"
style={{ cursor: 'pointer' }}
onClick={handleRedirectPayment}
>
Process Payment
</button>
</div>
)
}
if (order === '' || subTotal === '') {
return (
<div className='grid-container'>
<Canonical url="https://www.bitelandia.com/Order" />

<button
className="proceedBtn" style={{ cursor: 'pointer' }}
onClick={handleCancel}
>
Go home
</button>

<h1>nothing to order</h1>

</div>
)
}else{
return(
<div className='ordercontainer'>
<Canonical url="https://www.bitelandia.com/Order" />
<div className='summarydiv'>
<table className='tableOrder'>
<thead>
<tr>
<th colSpan="2">Order Summary</th>
</tr>
</thead>
<tbody>
<tr>
<td>Subtotal:</td>
<td>${(subTotal*1).toFixed(2)}</td>
</tr>
<tr>
<td>Delivery Fee:</td>
<td>${(deliveryFee*1).toFixed(2)}</td>
</tr>
{/* <tr>
<td>Total before taxes:</td>
<td>${(totalBeforeTaxes*1).toFixed(2)}</td>
</tr>
<tr>
<td>Taxes (13%):</td>
<td>${(taxes*1).toFixed(2)}</td>
</tr> */}
<tr>
<td>Total Order:</td>
<td>${(TotalOrder*1).toFixed(2)}</td>
</tr>
</tbody>
</table>
</div>





<button className="proceedBtn" style={{ cursor: 'pointer' }} onClick={CancelOrder}>Cancel Order</button>
{pcpdates==="0"? (
<h3 className='requireText'>Pick ups will be available soon</h3> 
) : (
"")
}

{dlvdates==="0"? (
<h3 className='requireText'>Deliveries will be available soon</h3>
) : (
""
)}

{(dlvdates !=="0" && pcpdates !=="0") ?  (
<div> 
<input
type="checkbox"
value="Delivery"
checked={howdelivery==="Delivery"}
disabled={howdelivery==='Pick up'}
onChange={handleSetDelivery}                 
/>
<label>
<span>Delivery</span>
</label>
<input
type="checkbox"
value="Pick up"
checked={howdelivery==="Pick up"}
disabled={howdelivery==='Delivery'}
onChange={handleSetDelivery}                 
/>
<label>
<span>Pick Up</span>
</label>
</div>
) : (
""
)}

<h3 className='requireText'>(*: required)</h3>

{howdelivery==="Pick up"? (
<div>  
<PickupsOnly 
cakeson={cakeson} 
tamalon={tamalon}
myname={myname}
myemail={myemail}
myphone={myphone}
selectedDate={selectedDate}
setSelectedDate={setSelectedDate}
setSelectedTime={setSelectedTime}
selectedTime={selectedTime}
save={save}
loading7={loading7}
data7={data7}
handleEmail={handleEmail}
handleName={handleName}
handlePhone={handlePhone}



/>
</div>
) : (
""
)}
{howdelivery==="Delivery"? (
<div>  
<DeliveryOnly
cakeson={cakeson} 
tamalon={tamalon}
myemail={myemail}
myname={myname}
myphone={myphone}
myaddress={myaddress}
mypostalcode={mypostalcode}
selectedDate={selectedDate}
selectedTime={selectedTime}
deliveryInstructions={deliveryInstructions}
save={save}
handleAddress={handleAddress}
handleDeliveryInstructionsChange={handleDeliveryInstructionsChange}
handleEmail={handleEmail}
handleName={handleName}
handlePhone={handlePhone}
handlePostalCode={handlePostalCode}
setSelectedCity={setSelectedCity}
selectedCity={selectedCity}
setSelectedDate={setSelectedDate}
setSelectedTime={setSelectedTime}
loading7={loading7}
error7={error7}
data7={data7}
alldates={alldates}

/>
</div>
) : (
""
)}

</div>
)
}
}

export default Order;