import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { QUERY_ORDER} from '../utils/queries';
import {useEffect } from 'react';
import Canonical from '../components/Canonical'

const Confirmation= ({
   setSubtotal,
   setTotalOrder,
   setorder,
   setSelectedDate,
  setSelectedTime,
   setmyemail,
   setmyname,
   setmyphone,
   setmyaddress,
   setIsFilled, 
}

) => {

   const { orderId } = useParams();

   const canonicalUrl = `https://www.bitelandia.com/${orderId}`;

   const { loading, data } = useQuery(QUERY_ORDER, {
    
     variables: { orderId: orderId },
   });

   useEffect(() => {
      setorder('');
      setSelectedDate('');
      setIsFilled(false);
      setSubtotal('');
      setTotalOrder('');
      setSelectedTime('');
      setmyemail('');
      setmyname('');
      setmyphone('');
      setmyaddress('');
    }, [    setorder,
      setSelectedDate,
    setIsFilled,
    setSubtotal,
    setTotalOrder,
    setSelectedTime,
    setmyemail,
    setmyname,
    setmyphone,
    setmyaddress]);
   
   const handleRedirectPayment = () =>{
  
      window.location.replace('/cakes_desserts_main')

  }
  const order = data?.Order || {};

   
   if(loading){
      return(
      
         <div>
            <Canonical url={canonicalUrl} />
             loading...
         </div>
 )   
      }
 if((order._id===undefined)||(order._id===null)){
   return(
      <div className='grid-container'>
         <Canonical url={canonicalUrl} />
        <button className='proceedBtn'
  style={{ cursor: 'pointer' }}
  onClick={handleRedirectPayment}
  >
  Go home
  </button>
  <h1>no order has been received</h1>
      </div>
    )
   
      
 
   }else{
      return(
         <div className='grid-container'>
            <Canonical url={canonicalUrl} />
           <button className='proceedBtn'
     style={{ cursor: 'pointer' }}
     onClick={handleRedirectPayment}
     >
     Go home
     </button>
     <h1>your order #{order.invoice} was received. Please check your downloads folder to see your invoice. Thank you </h1>
         </div>
       )
   }
}

export default Confirmation;
